import _ from 'lodash'
import DashboardApi from '../api/dashboard'
import ProductsApi from '../api/products'
import MetricsApi from '../api/metrics'
import { createScoringRecord } from '../../../../va-corejs-v3/actions/scoring'

// INIT DASHBOARD LAST PRODUCT

export function initDashboardLastProduct(id) {
  return async dispatch => {
    const lastProduct = await ProductsApi.find(id)
    if (lastProduct) {
      dispatch({
        type: 'DSH/SET_LAST_PRODUCT',
        lastProduct,
      })
    }
  }
}

// INIT DASHBOARD METRICS

export function initDashboardProductsMetrics() {
  return async (dispatch, getState) => {
    // init
    const metrics = {}

    // products
    metrics.products = {}

    // all products
    metrics.products.total = {}
    metrics.products.total.label = 'Total'
    metrics.products.total.year = await MetricsApi.get({ type: 'products', from: '2022-12-31' })
    metrics.products.total.total = await MetricsApi.get({ type: 'products' })

    // products by status
    const lastYear = new Date().getFullYear() - 1
    const state = getState()
    const { status, environment } = state
    const statusList = _.get(status, 'list', [])

    await Promise.all(
      statusList.map(async currStatus => {
        const { slug } = currStatus

        // Exclude "configuration" status as requested by the Value Able
        // TODO: this is temporary because it is hardcoded and it is not good. Find a better way.
        if (slug === 'configuration') return

        metrics.products[slug] = {}
        metrics.products[slug].label = _.get(currStatus, `name.${[environment.lang]}`)
        metrics.products[slug].year = await MetricsApi.get({
          type: 'products',
          from: `${lastYear}-12-31`,
          status: slug,
        })
        metrics.products[slug].total = await MetricsApi.get({ type: 'products', status: slug })
      })
    )

    if (metrics) {
      dispatch({
        type: 'DSH/SET_METRICS',
        metrics,
      })
    }
  }
}

// INIT DASHBOARD

export function initDashboard() {
  return async (dispatch, getState) => {
    // get states
    const state = getState()

    // get last displayed product
    const { authentication } = state
    const lastDisplayedProductId = authentication?.user?.last_displayed_product_id

    // retrieve the dashboard
    const response = await DashboardApi.get()

    // init last product
    if (lastDisplayedProductId) dispatch(initDashboardLastProduct(lastDisplayedProductId))

    // init media
    const media = response?.data?.[0]?.media || []
    dispatch({
      type: 'DSH/SET_MEDIA',
      media,
    })

    // init metrics app
    const metrics = {}
    metrics.app = response?.data?.[0]?.metrics || {}
    dispatch({
      type: 'DSH/SET_METRICS',
      metrics,
    })
  }
}

// OPEN CREATE PRODUCT MODAL

export function createProduct({ ...attr }) {
  return async dispatch => {
    const newScoringId = await createScoringRecord({ ...attr })

    dispatch({
      type: 'UPDATE_NEW_SCORING_PANEL_VISIBLE',
      newScoringId,
      newScoringPanelVisible: true,
    })
  }
}
