import React from 'react'

function RowCore({
  children,
  className,
  align = 'space-between',
  verticalAlign = 'center',
  textAlign,
  spacing,
  height = '100%',
  width,
  top,
  left,
  right,
  bottom,
  padding,
  columns,
  full,
  fixed,
  marginTop,
  marginBottom,
  marginLeft,
  marginRight,
  background,
  maxHeight,
  maxWidth,
  minWidth,
  minHeight,
}) {
  let resultAlign = align
  if (align === 'left') resultAlign = 'flex-start'
  if (align === 'right') resultAlign = 'flex-end'

  let resultVerticalAlign = verticalAlign
  if (verticalAlign === 'top') resultVerticalAlign = 'flex-start'
  if (verticalAlign === 'bottom') resultVerticalAlign = 'flex-end'

  const resultPadding =
    (padding || top || left || right || bottom) &&
    `${top ?? padding ?? 0}px ${right ?? padding ?? 0}px ${bottom ?? padding ?? 0}px ${left ?? padding ?? 0}px`

  return (
    <div
      className={className}
      style={{
        display: 'grid',
        gridTemplateRows: 'auto',
        gridAutoFlow: 'column',
        justifyContent: resultAlign,
        alignItems: resultVerticalAlign,
        height,
        width: full ? '100%' : width,
        padding: typeof padding === 'string' ? padding : resultPadding,
        gridTemplateColumns: columns,
        columnGap: spacing,
        position: fixed ? 'absolute' : null,
        background: background && `var(--color-${background})`,
        textAlign,
        maxHeight,
        minHeight,
        maxWidth,
        minWidth,
        marginTop,
        marginBottom,
        marginLeft,
        marginRight,
      }}
    >
      {children}
    </div>
  )
}

export default RowCore
