import * as React from 'react'
import { Dialog, Slide } from '@material-ui/core'

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction="up" ref={ref} {...props} />
})

const TransitionFromRight = React.forwardRef((props, ref) => {
  return <Slide direction="left" ref={ref} {...props} />
})

function Popup({
  open,
  setOpen,
  children,
  className,
  onClose,
  width,
  height,
  padding,
  background,
  backgroundBlur,
  round,
  shadow,
  textColor,
  appear,
  margin,
  position,
}) {
  const handleClose = () => {
    if (onClose) onClose()
    setOpen(false)
  }

  const transition = () => {
    if (appear === 'fromRight') return TransitionFromRight
    return Transition
  }

  let left
  let right
  let top
  let bottom

  if (position) {
    const leftIndex = position.indexOf('left')
    const rightIndex = position.indexOf('right')
    const topIndex = position.indexOf('top')
    const bottomIndex = position.indexOf('bottom')
    if (leftIndex !== -1) left = 0
    if (rightIndex !== -1) right = 0
    if (topIndex !== -1) top = 0
    if (bottomIndex !== -1) bottom = 0
  }

  return (
    <Dialog
      open={open}
      TransitionComponent={transition()}
      keepMounted
      onClose={handleClose}
      PaperProps={{
        className,
        style: {
          boxSizing: 'border-box',
          width,
          padding,
          height,
          background,
          backdropFilter: backgroundBlur ? `blur(${backgroundBlur}px)` : 'none',
          borderRadius: round,
          boxShadow: shadow,
          color: textColor,
          position: position && 'absolute',
          left,
          right,
          top,
          bottom,
          margin,
        },
      }}
    >
      {children}
    </Dialog>
  )
}

export default Popup
