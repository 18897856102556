import React from 'react'
import classNames from 'classnames'
import Card from '../../../../ui/components/surfaces/Card'
import styles from '../../../styles/CqiDashboard.module.scss'
import Loading from '../../../../ui/components/utility/Loading'
import SpinnerIcon from '../../../../../assets/svg/spinner-rolling-white.svg'

function CqiDashboardCard({
  width = '100%',
  height,
  background,
  textColor,
  link,
  open,
  children,
  onClick,
  onHover,
  clickable,
  loading,
}) {
  const backgroundLight = !!(background === '#fff' || background === 'white' || background === '#ffffff')

  const LoadingIcon = () => (
    <div style={{ filter: backgroundLight && 'brightness(0.5)' }}>
      <SpinnerIcon width={60} height={60} />
    </div>
  )

  return (
    <Card
      className={classNames(styles.CqiDashboard_card, {
        [styles.CqiDashboard_card___clickable]: onClick || link || clickable,
      })}
      width={width}
      height={height}
      background={background}
      textColor={textColor}
      link={link}
      open={open}
      onHover={onHover}
      onClick={onClick}
    >
      <Loading icon={<LoadingIcon />} active={loading}>
        {children}
      </Loading>
    </Card>
  )
}

export default CqiDashboardCard
