import React, { useState } from 'react'
import AnimateIcon from '../../../../ui/components/images/AnimateIcon'
import Column from '../../../../ui/components/layouts/Column'
import Row from '../../../../ui/components/layouts/Row'
import Text from '../../../../ui/components/typography/Text'
import ArrowIcon from '../../../../../assets/svg/arrow-forward.svg'
import Fix from '../../../../ui/components/layouts/Fix'
import styles from '../../../styles/CqiDashboard.module.scss'
import CqiDashboardCard from './CqiDashboardCard'

function CqiDashboardBlock({
  iconStart,
  iconEnd,
  iconSize,
  iconStrockWidth,
  iconStrokeRound,
  title,
  subtitle,
  footerComponent,
  headerComponent,
  background,
  iconBottom,
  iconTop,
  iconLeft,
  iconRight,
  iconHeight,
  iconWidth,
  icon,
  iconFixed,
  link,
  open,
  onClick,
  clickable,
  padding = '12px 25px 16px 25px',
  height,
}) {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <CqiDashboardCard
      height={height ?? 213}
      background={background}
      textColor="#fff"
      link={link}
      onClick={onClick}
      onHover={(link || onClick || clickable) && setIsHovered}
      open={open}
      clickable={clickable}
    >
      <Column rows="auto 1fr" full spacing={5} padding={padding} align="center">
        {headerComponent ?? (
          <Column right={30} spacing={5}>
            <Fix className={styles.CqiDashboard_card_arrowIcon} right={8} top={8}>
              <ArrowIcon />
            </Fix>
            <Text size={20} bold spacing={1.5}>
              {title}
            </Text>
            <Text size={14} medium spacing={1.2}>
              {subtitle}
            </Text>
          </Column>
        )}

        {((iconStart && iconEnd) || icon) && (
          <Row
            align="center"
            marginBottom={iconBottom}
            marginTop={iconTop}
            marginLeft={iconLeft}
            marginRight={iconRight}
            fixed={iconFixed}
          >
            {iconStart && iconEnd && (
              <AnimateIcon
                size={iconSize}
                height={iconHeight}
                width={iconWidth}
                strokeWidth={iconStrockWidth}
                strokeRound={iconStrokeRound}
                IconStart={iconStart}
                IconEnd={iconEnd}
                animate={isHovered}
              />
            )}
            {icon}
          </Row>
        )}
        {footerComponent}
      </Column>
    </CqiDashboardCard>
  )
}

export default CqiDashboardBlock
