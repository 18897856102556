import React from 'react'
import { useResponsive } from '../../services/device/useResponsive'

function Responsive({ children, size }) {
  const { isResponsiveSize } = useResponsive()

  return <>{isResponsiveSize(size) && children}</>
}

export default Responsive
