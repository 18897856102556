import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../state/actions'
import CqiDashboardSection from './common/CqiDashboardSection'
import CqiDashboardDownload from './common/CqiDashboardDownload'

function CqiDashboardReference({ media }) {
  const documents = media.filter(m => m.collection === 'documents').sort((a, b) => a.order - b.order)

  return (
    <>
      {media && documents && (
        <CqiDashboardSection title="Reference" columns={2} verticalSpacing={11}>
          {documents.map((document, index) => {
            return <CqiDashboardDownload key={index} file={document} />
          })}
        </CqiDashboardSection>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    media: state.dashboard?.media,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboardReference)
