import { httpGet } from '../../../../va-corejs-v3/api'

class DashboardApi {
  get = async () => {
    const response = await httpGet(`dashboards?filter[name]=default&include=media`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in DashboardApi.get')
    }

    return response.data
  }
}

const instance = new DashboardApi()

export default instance
