import React from 'react'
import { connect } from 'react-redux'

import './styles.scss'
import * as actionCreators from '../../store/actions'
import screenTypesMap from '../../layout/_parts/screenTypesMap'

const classNames = require('classnames')

export class HomeComponent extends React.Component {
  componentDidMount = async () => {
    const { getHomeData } = this.props
    getHomeData()
  }

  render() {
    const { home, environment, device } = this.props

    function handleBlockClicked(link) {
      if (typeof window !== 'undefined') {
        window.location.href = link
      }
    }

    return (
      <div
        className={classNames({
          home_blocks_container: true,
          desk: device.screenType === screenTypesMap.desk,
          ipadh: device.screenType === screenTypesMap.iPadH,
          ipadv: device.screenType === screenTypesMap.iPadV,
          iphonexr: device.screenType === screenTypesMap.iPhoneXR,
          iphonese: device.screenType === screenTypesMap.iPhoneSE,
        })}
      >
        {home.blocks.map(item => {
          const widthCssClass = () => {
            switch (item.width) {
              case 'full':
                return 'width_full'
              case '1/3':
                return 'width_1_3'
              case '1/4':
                return 'width_1_4'
              default:
                return 'width_1_2'
            }
          }

          return item.type === 'youtube' ? (
            <div
              key={item.id}
              className={classNames({
                home_block: true,
                [widthCssClass()]: true,
              })}
            >
              <iframe
                width="100%"
                height="100%"
                src={item.url}
                title={item.title[environment.defaultLang]}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          ) : (
            <div
              key={item.id}
              className={classNames({
                home_block: true,
                [widthCssClass()]: true,
              })}
              style={{
                backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0.00) 50%, rgba(0, 0, 0, 0.6) 88%), url(${`${item.url_blob}`})`,
              }}
              role="button"
              tabIndex={0}
              onClick={() => {
                handleBlockClicked(item.link)
              }}
              onKeyPress={() => {
                handleBlockClicked(item.link)
              }}
            >
              <div className={classNames({ 'block-texts-container': true })}>
                <div
                  className={classNames({
                    block_title: true,
                  })}
                >
                  {item.title[environment.defaultLang]}
                </div>
                <div
                  className={classNames({
                    block_description: true,
                  })}
                >
                  {item.description[environment.defaultLang]}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
}
const mapStateToProps = state => {
  return {
    environment: state.environment,
    device: state.device,
    home: state.home,
  }
}

export default connect(mapStateToProps, actionCreators)(HomeComponent)
