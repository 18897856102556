import React from 'react'

function Fix({
  className,
  children,
  top,
  left,
  right,
  bottom,
  back,
  front,
  fixed,
  height,
  width,
  full,
  marginLeft,
  marginRight,
  sticky,
  padding,
  center,
}) {
  let transform = null

  if (center) {
    transform = 'translate(-50%,-50%)'
  }

  return (
    <div
      className={className}
      style={{
        top: top ?? 'auto',
        left: left ?? 'auto',
        marginLeft: marginLeft || marginLeft === 0 ? marginLeft : null,
        marginRight: marginRight || marginRight === 0 ? marginRight : null,
        right: right ?? 'auto',
        bottom: bottom ?? 'auto',
        zIndex: (back && '0') || (front && '6'),
        position: (fixed && 'fixed') || (sticky && 'sticky') || 'absolute',
        height: height ?? 'fit-content',
        width: full ? '100%' : width ?? 'fit-content',
        padding,
        transform,
      }}
    >
      {children}
    </div>
  )
}

export default Fix
