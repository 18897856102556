import React from 'react'
import Row from '../layouts/Row'
import Text from '../typography/Text'

function Button({
  onClick,
  onHover,
  fontSize,
  fontWeight,
  disabled,
  className,
  text,
  full,
  fontColor,
  iconBefore,
  iconAfter,
  color,
  width,
  height,
  padding,
  round,
}) {
  return (
    <div
      className={className}
      onClick={onClick}
      onKeyDown={onClick}
      onMouseEnter={() => onHover?.(true)}
      onMouseLeave={() => onHover?.(false)}
      role="button"
      tabIndex={0}
      style={{
        cursor: !disabled && 'pointer',
        width: full ? '100%' : 'fit-content',
        background: color,
        borderRadius: round,
        opacity: disabled && 0.5,
        pointerEvents: disabled && 'none',
      }}
    >
      <Row width={width} height={height} padding={padding} align="center">
        {iconBefore}
        <Text size={fontSize} weight={fontWeight} color={fontColor}>
          {text}
        </Text>
        {iconAfter}
      </Row>
    </div>
  )
}

export default Button
