import React from 'react'
import { MorphReplace } from 'react-svg-morph'

/**
 * Component for animate icons
 *
 * @displayName AnimateIcon
 *
 * @property {reactComponent} IconStart - icon for start animation
 * @property {reactComponent} IconEnd - icon for end animation
 * @property {boolean} animate - boolean value for animate
 * @property {number} size - size of icon
 * @property {number} width - width of icon
 * @property {number} height - height of icon
 * @property {number} strokeWidth - stroke width of icon
 * @property {boolean} strokeRound - stroke round of icon
 *
 * @example
 *
 * <AnimateIcon
 *  StartIcon={LastScoringIconStart}
 *  EndIcon={LastScoringIconEnd}
 * animate={checked}
 * />
 *
 */

function AnimateIcon({ IconStart, IconEnd, size, width, strokeWidth, strokeRound, height, animate }) {
  return (
    <MorphReplace
      width={width ?? size ?? null}
      height={height ?? size ?? null}
      rotation="none"
      style={{ strokeWidth, strokeLinecap: strokeRound && 'round' }}
    >
      {animate ? <IconEnd key="end" /> : <IconStart key="start" />}
    </MorphReplace>
  )
}

export default AnimateIcon
