import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../state/actions'
import CqiDashboardSmallBlock from './common/CqiDashboardSmallBlock'
import Popup from '../../../ui/components/dialog/Popup'
import Text from '../../../ui/components/typography/Text'
import Row from '../../../ui/components/layouts/Row'
import CloseIcon from '../../../../assets/svg/x.svg'
import PlayIcon from '../../assets/svg/play.svg'
import Interact from '../../../ui/components/buttons/Interact'
import CqiDashboardPlayer from './common/CqiDashboardPlayer'

function CqiDashboardVideo({ media }) {
  const [openVideoPopup, setOpenVideoPopup] = useState(false)

  const video = media?.filter(m => m.collection === 'videos').sort((a, b) => a.order - b.order)?.[0]

  return (
    <>
      {media && (
        <>
          <CqiDashboardSmallBlock
            title="How to Score"
            height={180}
            onClick={() => setOpenVideoPopup(true)}
            icon={<PlayIcon />}
            background="#6C936C url(/cqi/video-bg.jpg) no-repeat center center / cover"
          />
          {video && (
            <Popup
              open={openVideoPopup}
              setOpen={setOpenVideoPopup}
              width={731}
              background="rgba(0, 0, 0, 0.50)"
              backgroundBlur={32}
              textColor="#fff"
              round={10}
              padding={20}
            >
              <Row height="auto" full bottom={14}>
                <Text size={16}>{video?.name}</Text>
                <Interact onClick={() => setOpenVideoPopup(false)}>
                  <CloseIcon />
                </Interact>
              </Row>
              <CqiDashboardPlayer video={video} pause={!openVideoPopup} />
            </Popup>
          )}
        </>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    media: state.dashboard?.media,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboardVideo)
