import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../state/actions'
import CqiDashboardBars from './common/CqiDashboardBars'

function CqiDashboardProductsMetrics({ status, productsMetricts, initDashboardProductsMetrics }) {
  const [cachedStatus, setCachedStatus] = useState([])

  useEffect(() => {
    setCachedStatus(status)
  }, [])

  useEffect(() => {
    // cachedStatus is needed because otherwise the simple useEffect would always be triggered
    if (!_.isEqual(status, cachedStatus)) {
      initDashboardProductsMetrics()
      setCachedStatus(status)
    }
  }, [status])

  const barsColor = ['#3D4A3D', '#92B092', '#526352', '#779A77']

  const data = productsMetricts
    ? Object.keys(productsMetricts).map((metric, index) => {
        return {
          label: productsMetricts[metric].label,
          value: productsMetricts[metric].year,
          total: productsMetricts[metric].total,
          color: barsColor[index],
        }
      })
    : null

  return <CqiDashboardBars title="Product Number This Year / All Time" data={data} />
}

const mapStateToProps = state => {
  return {
    status: state.status,
    productsMetricts: state.dashboard?.metrics?.products,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboardProductsMetrics)
