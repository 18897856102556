import React from 'react'
import Link from '../navigation/Link'

function Card({
  children,
  className,
  textAlign,
  height = 'auto',
  width = '100%',
  top,
  left,
  right,
  bottom,
  padding,
  full,
  fixed,
  round = 10,
  background,
  maxHeight,
  maxWidth,
  minWidth,
  minHeight,
  shadow,
  textColor,
  link,
  open,
  elevate,
  onClick,
  onHover,
}) {
  if (elevate) shadow = '0px 0px 25px 0px rgba(190, 190, 190, 0.30)'

  return (
    <div
      className={className}
      style={{
        overflow: 'hidden',
        height,
        width: full ? '100%' : width,
        marginTop: top,
        marginLeft: left,
        marginRight: right,
        marginBottom: bottom,
        padding,
        position: fixed ? 'absolute' : 'relative',
        background,
        textAlign,
        maxHeight,
        minHeight,
        maxWidth,
        minWidth,
        borderRadius: round,
        boxShadow: shadow,
        color: textColor,
        cursor: onClick && 'pointer',
      }}
      onMouseEnter={() => onHover?.(true)}
      onMouseLeave={() => onHover?.(false)}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={0}
    >
      <Link href={link} open={open}>
        {children}
      </Link>
    </div>
  )
}

export default Card
