import React, { useState } from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../../state/actions'
import CqiDashboardSmallBlock from './CqiDashboardSmallBlock'
import Popup from '../../../../ui/components/dialog/Popup'
import Row from '../../../../ui/components/layouts/Row'
import Column from '../../../../ui/components/layouts/Column'
import Text from '../../../../ui/components/typography/Text'
import Icon from '../../../../ui/components/images/Icon'
import Button from '../../../../ui/components/buttons/Button'
import ContactApi from '../../../api/contact'
import styles from '../../../styles/CqiDashboard.module.scss'

function CqiDashboardContactBlock({
  background,
  icon,
  iconTop,
  title,
  titleSpacing,
  subtitle,
  popupTitle,
  request = 'New Message',
}) {
  const [openContactPopup, setOpenContactPopup] = useState(false)
  const [text, setText] = useState('')

  const handleSubmit = () => {
    ContactApi.send({
      subject: request,
      message: text,
    })
    setOpenContactPopup(false)
    setText('')
  }

  return (
    <>
      <CqiDashboardSmallBlock
        onClick={() => setOpenContactPopup(true)}
        title={title}
        subtitle={subtitle}
        background={background}
        icon={icon}
        iconTop={iconTop}
        titleSpacing={titleSpacing}
      />
      <Popup
        open={openContactPopup}
        setOpen={setOpenContactPopup}
        width={380}
        height={600}
        background="#fff"
        round={10}
        padding={24}
        appear="fromRight"
        position="bottom right"
      >
        <Column rows="auto auto 1fr auto" spacing={15} verticalAlign="justify-content" full>
          <Row spacing={15} full bottom={10}>
            <Icon svg={icon} height={40} color={background} />
            <Text>{popupTitle}</Text>
          </Row>
          <div>{/* To DO  Suggestions  Selector */}</div>
          <textarea
            className={styles.CqiDashboard_textarea}
            placeholder="Describe it in few words.."
            value={text}
            onChange={({ target }) => setText(target.value)}
          />
          <Row align="right" full>
            <Button
              text="Send"
              onClick={handleSubmit}
              width={165}
              height={50}
              color={background}
              round={8}
              fontColor="#fff"
              fontWeight="bold"
              fontSize={17}
              disabled={!text}
            />
          </Row>
        </Column>
      </Popup>
    </>
  )
}

const mapStateToProps = state => {
  return {
    media: state.dashboard?.media,
    environment: state.environment,
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboardContactBlock)
