import React from 'react'
import { connect } from 'react-redux'

import Layout from 'layout'
import * as actionCreators from '../store/actions'

import CqiDashboard from '../modules/cqi/views/CqiDashboard'
import Home from '../components/home'

export class IndexPage extends React.Component {
  render() {
    return <Layout>{process.env.cqiDashboardEnabled === 'true' ? <CqiDashboard /> : <Home />}</Layout>
  }
}

const mapStateToProps = state => {
  return {
    user: state.authentication.user,
  }
}

export default connect(mapStateToProps, actionCreators)(IndexPage)
