import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import * as actionCreators from '../../state/actions'
import CqiDashboardBars from './common/CqiDashboardBars'
import config from '../../config.json'

function CqiDashboardMetrics({ appMetrics }) {
  const barsColor = ['#3D4A3D', '#92B092', '#77A677']
  const appMetricsConf = config && config?.metrics

  let counter = 0
  const data = appMetrics
    ? _.map(appMetricsConf, (metric, index) => {
        const value = {
          label: metric.label,
          value: appMetrics[index],
          total: metric.limit,
          color: barsColor[counter],
        }
        counter += 1
        return value
      })
    : null

  return <CqiDashboardBars title="App Metrics" data={data} hideTotal />
}

const mapStateToProps = state => {
  return {
    appMetrics: state.dashboard?.metrics?.app,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboardMetrics)
