import React from 'react'
import Column from '../../../../ui/components/layouts/Column'
import Text from '../../../../ui/components/typography/Text'
import Bar from '../../../../ui/components/data/Bar'
import CqiDashboardCard from './CqiDashboardCard'

function CqiDashboardBars({ title, data, hideTotal }) {
  return (
    <>
      <CqiDashboardCard height={213} background="#fff" loading={!data}>
        <Column rows="auto 1fr" full spacing={19} padding="17px 30px 24px 30px">
          <Text size={16} align="center">
            {title}
          </Text>
          <Column spacing={5} full>
            {data?.map((item, index) => (
              <Column spacing={5} verticalAlign="center" key={index}>
                <Text size={12} light color="rgba(0, 0, 0, 0.7)" firstCapital>
                  {`${item.label}: ${item.value}${hideTotal ? '' : ` / ${item.total}`}`}
                </Text>
                <Bar value={item.value} total={item.total} color={item.color} background="rgba(0, 0, 0, 0.1)" />
              </Column>
            ))}
          </Column>
        </Column>
      </CqiDashboardCard>
    </>
  )
}

export default CqiDashboardBars
