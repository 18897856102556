import React from 'react'
import Grid from '../../../../ui/components/layouts/Grid'
import Column from '../../../../ui/components/layouts/Column'
import Text from '../../../../ui/components/typography/Text'
import { useResponsive } from '../../../../ui/services/device/useResponsive'

function CqiDashboardSection({
  columns = 4,
  tabletColumns = 2,
  spacing = 20,
  title,
  children,
  verticalSpacing,
  horizontalSpacing,
}) {
  const { isDesktopSize } = useResponsive()

  return (
    <Column spacing={8} verticalAlign="top" full>
      <Text size={18} medium spacing={1.2} left={10} color="rgba(0, 0, 0, 0.65)">
        {title}
      </Text>
      <Grid
        columns={isDesktopSize ? columns : tabletColumns}
        align="center"
        spacing={spacing}
        verticalSpacing={verticalSpacing}
        horizontalSpacing={horizontalSpacing}
      >
        {children}
      </Grid>
    </Column>
  )
}

export default CqiDashboardSection
