import React from 'react'

function Bar({ value, total, color, height = 10, background }) {
  const percent = (value / total) * 100

  return (
    <div
      className="Bar"
      style={{
        width: '100%',
        height,
        borderRadius: height / 2,
        background,
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <div
        className="Bar_value"
        style={{
          width: `${percent}%`,
          height: '100%',
          background: color,
          position: 'absolute',
          borderRadius: height / 2,
          top: 0,
          left: 0,
        }}
      />
    </div>
  )
}

export default Bar
