import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../state/actions'
import CqiDashboardSection from './common/CqiDashboardSection'
import SuccessIcon from '../../assets/svg/success.svg'
import SupportIcon from '../../assets/svg/support.svg'
import CqiDashboardContactBlock from './common/CqiDashboardContactBlock'

function CqiDashboardContact() {
  return (
    <CqiDashboardSection title="Contact" columns={2} spacing={16}>
      <CqiDashboardContactBlock
        title="Customer Success"
        subtitle="Any suggestion? Please tell us about it!"
        background="#77A677"
        icon={<SuccessIcon />}
        iconTop={3}
        titleSpacing={5}
        popupTitle="Have a suggestion? Please make us know !"
        request="New Suggestion"
      />
      <CqiDashboardContactBlock
        title="Support"
        subtitle="Need Help ? Contact our support"
        background="#3E4B3E"
        icon={<SupportIcon />}
        iconTop={10}
        iconsize={50}
        popupTitle="Need Help ? Please describe your issue."
        request="New Issue"
      />
    </CqiDashboardSection>
  )
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboardContact)
