import React from 'react'
import { connect } from 'react-redux'
import * as actionCreators from '../../state/actions'
import * as renaultProjectModeActionCreators from '../../../renault-project-mode/state/actions'
import CqiDashboardCard from './common/CqiDashboardCard'
import Grid from '../../../ui/components/layouts/Grid'
import Column from '../../../ui/components/layouts/Column'
import Text from '../../../ui/components/typography/Text'
import Fix from '../../../ui/components/layouts/Fix'
import CreateIcon from '../../assets/svg/create_button.svg'
import styles from '../../styles/CqiDashboard.module.scss'
import config from '../../config.json'

function CqiDashboardCreate({ createProduct, initNewProject }) {
  // load button config from config.json
  const buttons = config?.create_product_buttons

  // handle create button action
  const handleCreateButton = action => {
    switch (action) {
      case 'create_benchmark':
        createProduct({ status: 'benchmark' })
        break
      case 'create_project':
        initNewProject().then(project => {
          window.location.assign(`/project-mode/project/edit?id=${project.id}`)
        })
        break
      default:
        break
    }
  }

  return (
    <>
      <CqiDashboardCard height={213} background="#777" textColor="#fff" clickable>
        <Column rows="auto 1fr" spacing={1} full>
          <Column spacing={5} padding="12px 10px 10px 23px" background="#3E4B3E">
            <Text size={20} bold spacing={1.5}>
              Create New
            </Text>
          </Column>

          <Grid columns={2} height="100%" spacing={1} relative>
            <Fix center left="50%" right="50%" top="50%" bottom="50%">
              <CreateIcon />
            </Fix>

            {buttons &&
              buttons.map((button, index) => (
                <Column
                  className={styles.CqiDashboard_createProductButton}
                  verticalAlign="center"
                  align="center"
                  texAlign="center"
                  background="#3E4B3E"
                  onClick={() => handleCreateButton(button.action)}
                  full
                  key={index}
                >
                  <Text
                    size={16}
                    bold
                    shadow="0px 5px 10px rgba(0, 0, 0, 0.15)"
                    color={(index % 4 === 2 || index % 4 === 3) && '#BCD5AB'}
                  >
                    {button.label}
                  </Text>
                </Column>
              ))}
          </Grid>
        </Column>
      </CqiDashboardCard>
    </>
  )
}

const mapStateToProps = state => {
  return {
    lastProduct: state.dashboard?.lastProduct,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, {
  ...actionCreators,
  ...renaultProjectModeActionCreators,
})(CqiDashboardCreate)
