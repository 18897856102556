import * as React from 'react'

function Loading({ active, icon, children }) {
  return (
    <>
      {active ? (
        <div
          style={{ display: 'grid', width: '100%', height: '100%', justifyContent: 'center', alignContent: 'center' }}
        >
          {icon}
        </div>
      ) : (
        children
      )}
    </>
  )
}

export default Loading
