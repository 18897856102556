import React, { useEffect, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { BigPlayButton, Player } from 'video-react'
import * as actionCreators from '../../../state/actions'
import { getMediaBlobUrl } from '../../../../../../va-corejs-v3/api'
import styles from '../../../styles/CqiDashboardPlayer.module.scss'
import PlayIcon from '../../../assets/svg/play.svg'
import SpinnerIcon from '../../../../../assets/svg/spinner-rolling-white.svg'

function CqiDashboardReference({ video, pause }) {
  const [videoUrl, setVideoUrl] = useState(null)
  const [isStarted, setIsStarted] = useState(false)
  const [isLoaded, setIsLoaded] = useState(false)

  const playerRef = useRef(null)

  useEffect(() => {
    const getVideoUrl = async v => {
      const blobUrl = await getMediaBlobUrl(v?.url)
      setVideoUrl(blobUrl)
    }

    if (video) getVideoUrl(video)
  }, [])

  useEffect(() => {
    // This is needed to force re-render of the component because ther previous async useEffect don't do it
    if (videoUrl && !isLoaded) setIsLoaded(true)
  }, [videoUrl])

  // player state change handler
  // used for detecting if is started
  const handleStateChange = state => {
    if (state.hasStarted !== isStarted) setIsStarted(state.hasStarted)
  }

  if (playerRef.current) playerRef.current.subscribeToStateChange(handleStateChange)

  // if pause property is true, pause the video
  // used to pause video when popup is closed
  if (playerRef.current && pause) playerRef.current.pause()

  return (
    <>
      {videoUrl && (
        <div className={styles.CqiDashboardPlayer} style={{ borderRadius: 10, overflow: 'hidden' }}>
          {!isStarted && playerRef.current && (
            <PlayIcon
              className={styles.CqiDashboardPlayer_playIcon}
              onClick={() => {
                if (playerRef.current) playerRef.current.play()
              }}
            />
          )}

          <Player ref={playerRef} src={videoUrl} poster="/cqi/video-bg.jpg" playsInline fluid width="100%">
            <BigPlayButton disabled />
          </Player>
        </div>
      )}

      {!videoUrl && (
        <div className={styles.CqiDashboardPlayer} style={{ borderRadius: 10, overflow: 'hidden' }}>
          <div style={{ filter: true && 'brightness(0.5)' }}>
            <SpinnerIcon width={60} height={60} />
          </div>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboardReference)
