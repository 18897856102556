import React from 'react'
import ColumnCore from './ColumnCore'
import { useResponsive } from '../../services/device/useResponsive'

function Column(props) {
  const { mobile, responsiveProps, responsive, children } = props

  const { isMobileSize, isResponsiveSize } = useResponsive()

  let propsMobile
  let propsResponsive

  if (mobile) {
    propsMobile = { ...props }
    delete propsMobile.mobile
    propsMobile = { ...propsMobile, ...mobile }
  }

  if (responsiveProps) {
    propsResponsive = { ...props }
    delete propsResponsive.responsiveProps
    propsResponsive = { ...propsResponsive, ...responsiveProps }
  }

  return (
    <>
      {((!mobile && !responsive) || (mobile && !isMobileSize) || (responsive && !isResponsiveSize(responsive))) && (
        <ColumnCore {...props}>{children}</ColumnCore>
      )}
      {propsMobile && isMobileSize && <ColumnCore {...propsMobile}>{children}</ColumnCore>}
      {responsive && isResponsiveSize(responsive) && <ColumnCore {...propsResponsive}>{children}</ColumnCore>}
    </>
  )
}

export default Column
