import React from 'react'

function ColumnCore({
  children,
  className,
  align = 'left',
  verticalAlign,
  spacing,
  height,
  width = '100%',
  top,
  left,
  right,
  bottom,
  padding,
  full,
  marginBottom,
  marginTop,
  onClick,
  marginLeft,
  marginRight,
  maxHeight,
  maxWidth,
  minWidth,
  minHeight,
  textAlign,
  background,
  style,
  border,
  fixed,
  rows,
  relative,
}) {
  let resultAlign = align
  if (align === 'left') resultAlign = 'flex-start'
  if (align === 'right') resultAlign = 'flex-end'

  let resultVerticalAlign = verticalAlign
  if (verticalAlign === 'top') resultVerticalAlign = 'flex-start'
  if (verticalAlign === 'bottom') resultVerticalAlign = 'flex-end'

  const resultPadding =
    (padding || top || left || right || bottom) &&
    `${top ?? padding ?? 0}px ${right ?? padding ?? 0}px ${bottom ?? padding ?? 0}px ${left ?? padding ?? 0}px`

  const resultPosition = () => {
    if (fixed) return 'absolute'
    if (relative) return 'relative'
    return null
  }

  return (
    <div
      className={className}
      style={{
        boxSizing: 'border-box',
        position: resultPosition(),
        display: 'grid',
        gridAutoFlow: 'row',
        alignItems: resultAlign,
        justifyItems: resultAlign,
        alignContent: resultVerticalAlign,
        height: full ? '100%' : height,
        rowGap: spacing,
        gridTemplateRows: rows,
        width,
        padding: typeof padding === 'string' ? padding : resultPadding,
        background,
        textAlign,
        border: border && `${border}px solid`,
        maxHeight,
        minHeight,
        maxWidth,
        minWidth,
        marginBottom,
        marginTop,
        marginLeft,
        marginRight,
        overflow: maxHeight && 'hidden',
        cursor: onClick && 'pointer',
        ...style,
      }}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={onClick && 0}
    >
      {children}
    </div>
  )
}

export default ColumnCore
