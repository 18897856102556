import { httpGet } from '../../../../va-corejs-v3/api'

class ProductsApi {
  find = async id => {
    const response = await httpGet(`products/${id}`)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in ProductsApi.find')
    }

    return response.data
  }
}

const instance = new ProductsApi()

export default instance
