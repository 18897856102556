import React from 'react'

function TextCore({
  heading,
  weight,
  medium,
  bold,
  tag,
  className,
  children,
  padding,
  color,
  align,
  firstCapital,
  size,
  height,
  top,
  left,
  right,
  bottom,
  trunc,
  uppercase,
  after,
  before,
  lastWordColor,
  spacing,
  shadow,
}) {
  const TextTag = `${tag ?? heading ? `h${heading}` : 'span'}`

  const formatText = text => {
    if (text && firstCapital)
      text =
        text
          ?.toString()
          .charAt(0)
          .toUpperCase() + text.slice(1)
    if (text && trunc && text.length > trunc) return `${text.slice(0, trunc)}...`

    if (text && lastWordColor) {
      const lastWord = text.split(' ').pop()
      const rest = text.slice(0, text.length - lastWord.length)
      return (
        <span>
          {rest}
          <span
            style={{
              color: lastWordColor,
            }}
          >
            {lastWord}
          </span>
        </span>
      )
    }

    return text
  }

  return (
    <TextTag
      className={className}
      style={{
        padding,
        textAlign: align,
        marginTop: top,
        marginLeft: left,
        marginRight: right,
        marginBottom: bottom,
        textTransform: uppercase && 'uppercase',
        color,
        fontSize: size,
        lineHeight: `${height}px`,
        fontWeight: weight || (medium && '500') || (bold && 'bold'),
        letterSpacing: spacing,
        textShadow: shadow,
      }}
    >
      {before}
      {formatText(children)}
      {after}
    </TextTag>
  )
}

export default TextCore
