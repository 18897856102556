import React from 'react'
import styles from '../../styles/Icon.module.scss'

function Icon({ size, svg, width, height, color }) {
  // load svg from folder by name proprety
  /*
  const ImportedIconRef = useRef(null)
  const folder = config.icons_folder
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const importIcon = async () => {
      try {
        const { default: namedImport } = await import(`../../../../${folder}/${name}.svg`)
        ImportedIconRef.current = namedImport
      } finally {
        setLoading(false)
      }
    }
    if (name) importIcon()
  }, [name])

  // load svg from folder
  if (!loading && name && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef
    return <ImportedIcon width={width ?? size} height={height ?? size} fill={color} />
  }
  */

  // render svg within a given component icon
  return (
    <div
      className={`${styles.Icon} ${color && styles.Icon___colored}`}
      style={{
        width: width ?? size,
        height: height ?? size,
        '--icon-color': color,
      }}
    >
      {svg}
    </div>
  )
}

export default Icon
