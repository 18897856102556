import React from 'react'

function Link({ href, open, condition, children }) {
  return (
    <>
      {condition || href ? (
        <a
          href={href}
          target={open === 'new' ? '_blank' : null}
          rel="noopener noreferrer"
          style={{ color: 'inherit', textDecoration: 'none' }}
        >
          {children}
        </a>
      ) : (
        children
      )}
    </>
  )
}

export default Link
