import React from 'react'
import { useResponsive } from '../../services/device/useResponsive'
import TextCore from './TextCore.js'

function Text(props) {
  const { isMobileSize } = useResponsive()

  let propsMobile
  const { mobile, children } = props

  if (mobile) {
    propsMobile = { ...props }
    delete propsMobile.mobile
    propsMobile = { ...propsMobile, ...mobile }
  }

  return (
    <>
      {(!mobile || !isMobileSize) && <TextCore {...props}>{children}</TextCore>}
      {propsMobile && isMobileSize && <TextCore {...propsMobile}>{children}</TextCore>}
    </>
  )
}

export default Text
