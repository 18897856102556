import React from 'react'

/**
 * Component for grid layout
 *
 * @displayName Grid
 *
 * @property {string} className - class name for component
 * @property {string} align - align of grid (left, center, right, space-between, space-around, space-evenly)
 * @property {string} verticalAlign - vertical align of grid (top, center, bottom)
 * @property {string} spacing - spacing between grid items
 * @property {string} height - height of grid
 * @property {string} width - width of grid
 * @property {string} maxWidth - max width of grid
 * @property {string} padding - padding of grid
 * @property {string} rows - rows of grid
 * @property {string} columns - columns of grid
 *
 * @example
 *
 * A grid with 2 rows and 2 columns
 *
 * <Grid
 *    align="space-between"
 *    verticalAlign="center"
 *    spacing="10px"
 *    height="100px"
 *    width="100%"
 *    maxWidth="100%"
 *    padding="10px"
 *    rows="2"
 *    columns="2"
 * >
 *  <div>1</div>
 *  <div>2</div>
 *  <div>3</div>
 *  <div>4</div>
 * </Grid>
 *
 */

function Grid({
  children,
  className,
  align = 'space-between',
  verticalAlign,
  spacing,
  horizontalSpacing,
  verticalSpacing,
  height,
  width = '100%',
  maxWidth,
  padding,
  rows,
  background,
  columns,
  top,
  relative,
}) {
  let resultAlign = align
  if (align === 'left') resultAlign = 'flex-start'
  if (align === 'right') resultAlign = 'flex-end'

  let resultVerticalAlign = verticalAlign
  if (verticalAlign === 'top') resultVerticalAlign = 'flex-start'
  if (verticalAlign === 'bottom') resultVerticalAlign = 'flex-end'

  return (
    <div
      className={className}
      style={{
        display: 'grid',
        gridAutoFlow: 'row',
        position: relative && 'relative',
        height,
        width,
        maxWidth,
        background,
        alignItems: resultVerticalAlign,
        justifyContent: resultAlign,
        padding,
        gridTemplateColumns: columns ? `repeat(${columns}, 1fr)` : '',
        gridTemplateRows: rows ? `repeat(${rows}, 1fr)` : null,
        columnGap: horizontalSpacing ?? spacing,
        rowGap: verticalSpacing ?? spacing,
        marginTop: top,
      }}
    >
      {children}
    </div>
  )
}

export default Grid
