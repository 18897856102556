import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import BenchmarkIconStart from '../assets/svg/benchmark_start.svg'
import BenchmarkIconEnd from '../assets/svg/benchmark_end.svg'
import SettingIconStart from '../assets/svg/setting_start.svg'
import UserIcon from '../assets/svg/user.svg'
import SettingIconEnd from '../assets/svg/setting_end.svg'
import FactsIconStart from '../assets/svg/facts_start.svg'
import FactsIconEnd from '../assets/svg/facts_end.svg'
import RobotIconStart from '../assets/svg/robot_start.svg'
import RobotIconEnd from '../assets/svg/robot_end.svg'
import ChartsIcon from '../assets/svg/charts.svg'
import CqiDashboardBlock from './components/common/CqiDashboardBlock'
import Column from '../../ui/components/layouts/Column'
import Row from '../../ui/components/layouts/Row'
import * as actionCreators from '../state/actions'
import CqiDashboardSection from './components/common/CqiDashboardSection'
import CqiDashboardLastProduct from './components/CqiDashboardLastProduct'
import styles from '../styles/CqiDashboard.module.scss'
import CqiDashboardMetrics from './components/CqiDashboardMetrics'
import CqiDashboardReference from './components/CqiDashboardReference'
import CqiDashboardVideo from './components/CqiDashboardVideo'
import CqiDashboardContact from './components/CqiDashboardContact'
import CqiDashboardSmallBlock from './components/common/CqiDashboardSmallBlock'
import CqiDashboardCreateProduct from './components/CqiDashboardCreate'
import CqiDashboardProductsMetrics from './components/CqiDashboardProductsMetrics'
import Text from '../../ui/components/typography/Text'
import Desktop from '../../ui/components/device/Desktop'

function CqiDashboard({ initDashboard }) {
  useEffect(() => {
    initDashboard()
  }, [])

  return (
    <div className={styles.CqiDashboard}>
      <Column spacing={20} maxWidth={1338} style={{ margin: '0 auto' }}>
        {/* Header */}
        <Row full top={20} bottom={20}>
          <Row spacing={15}>
            <UserIcon />
            <Text size={24} bold spacing={1.5} color="rgba(0, 0, 0, 0.70)">
              Customer Quality Intelligence
            </Text>
          </Row>
          <Desktop>
            <Text size={22} bold spacing={1.5} color="rgba(0, 0, 0, 0.70)">
              {moment().format('MMMM Do YYYY')}
            </Text>
          </Desktop>
        </Row>
        {/* Row 1 */}
        <CqiDashboardSection title="Browse">
          {/* Block 1: Last Product */}
          <CqiDashboardLastProduct />

          {/* Block 2: Search Products */}
          <CqiDashboardBlock
            iconStart={BenchmarkIconStart}
            iconEnd={BenchmarkIconEnd}
            iconSize={100}
            iconHeight={70}
            iconStrockWidth={5}
            title="Value App Benchmark"
            subtitle="Benchmark & Analysis of market products"
            background="#526352"
            iconTop={-10}
            link="/products"
          />

          {/* Block 3: Project Mode */}
          <CqiDashboardBlock
            iconStart={SettingIconStart}
            iconEnd={SettingIconEnd}
            iconSize={90}
            title="Project"
            subtitle="Score, compare, manage data"
            background="#3D4A3D"
            iconTop={5}
            link="/project-mode"
          />

          {/* Block 4: Create Product and Project */}
          <CqiDashboardCreateProduct />
        </CqiDashboardSection>

        {/* Row 2 */}
        <CqiDashboardSection title="Analyze">
          {/* Block 5: Value Board */}
          <CqiDashboardBlock
            title="Value Board"
            subtitle="Business Intelligence Dashboard"
            background="#92B092"
            icon={<ChartsIcon />}
            iconTop={120}
            iconLeft={0}
            iconFixed
            link="http://51.210.121.175:8088/superset/dashboard/valueboard-pro"
          />

          {/* Block 6: Fact Data */}
          <CqiDashboardBlock
            title="Fact Data"
            subtitle="Specs & Features"
            background="#6C936C"
            iconStart={FactsIconStart}
            iconEnd={FactsIconEnd}
            iconSize={90}
            iconStrockWidth={9}
            // TO DO: insert link facts section
            // link={link Here}
            // then remove clickable property
            clickable
          />

          {/* Block 7: Products Numbers */}
          <CqiDashboardProductsMetrics />

          {/* Block 8: Metrics */}
          <CqiDashboardMetrics />
        </CqiDashboardSection>

        {/* Row 3 */}
        <Row
          columns="calc(50% - 10px) 1fr 1fr"
          tablet={{ columns: '1fr 1fr' }}
          mobile={{ columns: '1fr 1fr' }}
          spacing={20}
          align="center"
        >
          {/* Block 9-10: Reference */}
          <CqiDashboardReference />

          {/* Block 11: Tutorials */}
          <CqiDashboardSection title="Tutorials" columns={2} spacing={16}>
            <CqiDashboardVideo />
            <CqiDashboardSmallBlock
              title="Ask Nono"
              subtitle="Your AI copilot"
              background="#5D705D"
              height={180}
              iconStart={RobotIconStart}
              iconEnd={RobotIconEnd}
              iconSize={56}
              // TO DO: insert link facts section
              // link={link Here}
              // then remove clickable property
              clickable
            />
          </CqiDashboardSection>

          {/* Block 12: Contact */}
          <CqiDashboardContact />
        </Row>
      </Column>
    </div>
  )
}

const mapStateToProps = state => {
  return {
    authentication: state.authentication,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboard)
