import { httpGet } from '../../../../va-corejs-v3/api'

class MetricsApi {
  get = async ({ type, from, status }) => {
    if (!type) return false

    let url = `${type}?count=true`
    if (from) url += `&filter[created_after]=${from}`
    if (status) url += `&filter[status]=${status}`

    const response = await httpGet(url)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in MetricsApi.get')
    }

    return response.data
  }
}

const instance = new MetricsApi()

export default instance
