import React from 'react'
import Column from '../../../../ui/components/layouts/Column'
import Text from '../../../../ui/components/typography/Text'
import CqiDashboardBlock from './CqiDashboardBlock'

function CqiDashboardSmallBlock({ titleSpacing = 11, subtitle, title, ...props }) {
  const SmallHeader = () => (
    <Column spacing={titleSpacing} align="center" textAlign="center">
      <Text size={20} height={18} bold spacing={1}>
        {title}
      </Text>
      <Text size={13}>{subtitle}</Text>
    </Column>
  )

  return <CqiDashboardBlock height={180} headerComponent={<SmallHeader />} padding="16px 10px 27px 10px" {...props} />
}

export default CqiDashboardSmallBlock
