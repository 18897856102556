import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import LastScoringIconStart from '../../assets/svg/last_scoring_start.svg'
import LastScoringIconEnd from '../../assets/svg/last_scoring_end.svg'
import CqiDashboardBlock from './common/CqiDashboardBlock'
import * as actionCreators from '../../state/actions'

function CqiDashboardLastProduct({ lastProduct, environment }) {
  // subtitile
  const brandName = _.find(lastProduct?.props, { slug: 'brand' })?.value?.body?.[environment?.defaultLang]
  const modelName = _.find(lastProduct?.props, { slug: 'model' })?.value?.body?.[environment?.defaultLang]
  const name = `${brandName} ${modelName}`
  const subtitle = brandName && modelName && `Go back to ${name}`

  // link
  const id = lastProduct?.id
  const link = id ? `/scoring?id=${id}` : null

  // TO DO: add completed counter
  /*
  const completed = 75
  const ScoringCompleted = () => (
    <Row align="left" full>
      <Text size={13}>{`${completed}% completed`}</Text>
    </Row>
  )
  */

  return (
    <>
      {lastProduct && (
        <CqiDashboardBlock
          iconStart={LastScoringIconStart}
          iconEnd={LastScoringIconEnd}
          iconSize={85}
          iconStrockWidth={5.5}
          iconStrokeRound
          title="Last Scoring"
          subtitle={subtitle}
          // TO DO: add completed counter
          // footerComponent={<ScoringCompleted />}
          background="#526352"
          link={link}
        />
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    lastProduct: state.dashboard?.lastProduct,
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboardLastProduct)
