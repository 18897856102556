import React from 'react'
import { useResponsive } from '../../services/device/useResponsive'
import ColumnCore from './ColumnCore'
import RowCore from './RowCore'

// This is a Row component that can be used to create a responsive layout.
// It is based on the Column and Row components from the UI module.
// responsiveProps is a prop that can be used to set props for a specific responsive size.
// responsive is a prop that can be used to set a responsive size.
// mobile is a prop that can be used to set props for mobile size.
// tablet is a prop that can be used to set props for tablet size.

function Row(props) {
  const { mobile, tablet, responsiveProps, responsive, children } = props

  const { isMobileSize, isTabletSize, isResponsiveSize } = useResponsive()

  let propsMobile
  let propsTablet
  let propsResponsive

  if (mobile) {
    propsMobile = { ...props }
    delete propsMobile.mobile
    propsMobile = { ...propsMobile, ...mobile }
  }

  if (tablet) {
    propsTablet = { ...props }
    delete propsTablet.tablet
    propsTablet = { ...propsTablet, ...mobile }
  }

  if (responsiveProps) {
    propsResponsive = { ...props }
    delete propsResponsive.responsiveProps
    propsResponsive = { ...propsResponsive, ...responsiveProps }
  }

  return (
    <>
      {((!mobile && !tablet && !responsive) ||
        (mobile && !isMobileSize && tablet && !isTabletSize) ||
        (mobile && !isMobileSize && !tablet && !responsive) ||
        (tablet && !isTabletSize && !mobile && !responsive) ||
        (responsive && !isResponsiveSize(responsive))) && <RowCore {...props}>{children}</RowCore>}
      {propsMobile && isMobileSize && <ColumnCore {...propsMobile}>{children}</ColumnCore>}
      {propsTablet && isTabletSize && <ColumnCore {...propsTablet}>{children}</ColumnCore>}
      {responsive && isResponsiveSize(responsive) && <ColumnCore {...propsResponsive}>{children}</ColumnCore>}
    </>
  )
}

export default Row
