import { httpPost } from '../../../../va-corejs-v3/api'

class ContactApi {
  send = async attributes => {
    const response = await httpPost(`contacts/send`, attributes, false)

    if (!response || response.status > 299) {
      // TODO error handling
      throw new Error('Error in contacts')
    }

    return response.data
  }
}

const instance = new ContactApi()

export default instance
