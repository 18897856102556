import { useWindowSize } from './useWindowSize'
import config from '../../config.json'

export const useResponsive = () => {
  const responsiveSizes = config.responsive
  const windowSize = useWindowSize()

  const isResponsiveSize = size => {
    if (size && responsiveSizes && windowSize) {
      if (Array.isArray(size))
        return size.some(
          currSize =>
            responsiveSizes[currSize] &&
            (!responsiveSizes[currSize][0] ||
              (responsiveSizes[currSize][0] && windowSize.width > responsiveSizes[currSize][0])) &&
            (!responsiveSizes[currSize][1] ||
              (responsiveSizes[currSize][1] && windowSize.width <= responsiveSizes[currSize][1]))
        )
      return (
        responsiveSizes[size] &&
        (!responsiveSizes[size][0] || (responsiveSizes[size][0] && windowSize.width > responsiveSizes[size][0])) &&
        (!responsiveSizes[size][1] || (responsiveSizes[size][1] && windowSize.width <= responsiveSizes[size][1]))
      )
    }
    return false
  }

  const getResponsiveSize = () => {
    if (responsiveSizes && windowSize) {
      return Object.keys(responsiveSizes).find(size => isResponsiveSize(size))
    }
    return false
  }

  const isDesktopSize = () => {
    if (isResponsiveSize(['extra-large', 'large'])) return true
    return false
  }

  const isTabletSize = () => {
    if (isResponsiveSize(['medium'])) return true
    return false
  }

  const isMobileSize = () => {
    if (isResponsiveSize(['extra-small', 'small'])) return true
    return false
  }

  const getResponsiveClasses = () => {
    let classes = ''
    const classesArray = []
    const size = getResponsiveSize()

    if (size) classesArray.push(`device-size-${size}`)

    if (isMobileSize()) classesArray.push('app-mobile')
    else classesArray.push('app-desktop')

    if (classesArray.length > 0) classes = classesArray.join(' ')

    return classes
  }

  return {
    responsiveClasses: getResponsiveClasses(),
    isResponsiveSize,
    isMobileSize: isMobileSize(),
    isTabletSize: isTabletSize(),
    isDesktopSize: isDesktopSize(),
  }
}
