import React from 'react'

function Interact({ onClick, onHover, children, disabled, className, full }) {
  return (
    <>
      {disabled ? (
        <div className={className}>children</div>
      ) : (
        <div
          className={className}
          onClick={onClick}
          onKeyDown={onClick}
          onMouseEnter={() => onHover?.(true)}
          onMouseLeave={() => onHover?.(false)}
          role="button"
          tabIndex={0}
          style={{ cursor: 'pointer', width: full ? '100%' : 'fit-content', height: full ? '100%' : 'fit-content' }}
        >
          {children}
        </div>
      )}
    </>
  )
}

export default Interact
