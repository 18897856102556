import React, { useState } from 'react'
import { connect } from 'react-redux'
import Row from '../../../../ui/components/layouts/Row'
import Text from '../../../../ui/components/typography/Text'
import DownloadIcon from '../../../../../assets/svg/download-icon-black.svg'
import DocumentIcon from '../../../../../assets/svg/document.svg'
import CqiDashboardCard from './CqiDashboardCard'
import * as actionCreators from '../../../state/actions'
import { getMediaBlobUrl } from '../../../../../../va-corejs-v3/api'

function CqiDashboardDownload({ file }) {
  const [documentUrl, setDocumentUrl] = useState(null)
  const [isLoading, setIsLoading] = useState(false)

  const loadDocument = async () => {
    // Document lazy loading
    if (documentUrl === null && file && !isLoading) {
      setIsLoading(true)
      const blobUrl = await getMediaBlobUrl(file.url)
      setDocumentUrl(blobUrl)
      setIsLoading(false)
      window.open(blobUrl, '_blank')
    }
  }

  return (
    <CqiDashboardCard
      className="CqiDashboard_download"
      background="#fff"
      height={84}
      link={documentUrl}
      open="new"
      padding="12px 18px"
      loading={isLoading}
      onClick={loadDocument}
    >
      <Row columns="auto 1fr auto" full spacing={20} padding="16px 18px">
        <DocumentIcon width={16} height={16} />
        <Text size={16} trunc={30}>
          {file.name}
        </Text>
        <DownloadIcon width={16} height={16} />
      </Row>
    </CqiDashboardCard>
  )
}

const mapStateToProps = state => {
  return {
    environment: state.environment,
  }
}

export default connect(mapStateToProps, actionCreators)(CqiDashboardDownload)
